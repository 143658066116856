/**
 * Paypal Express compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

import PaypalBannersConfigQuery from './PaypalBannersConfig.query';
import PaypalSmartButtonsConfigQuery from './PaypalSmartButtonsConfig.query';

/** @namespace Scandiweb/PaypalExpress/Query/PaypalConfigs/Query */
export class PaypalConfigsQuery {
    getQuery() {
        return new Field('paypalConfigs')
            .addFieldList(this.getPaypalConfigsFields());
    }

    getPaypalConfigsFields() {
        return [
            'sdkUrl',
            'isVisibleOnCart',
            'isVisibleOnProductPage',
            'isGuestCheckoutAllowed',
            'paymentAcceptanceMarkUrl',
            'paymentAcceptanceMarkImageUrl',
            PaypalBannersConfigQuery.getQuery(),
            PaypalSmartButtonsConfigQuery.getQuery()
        ];
    }
}

export default new PaypalConfigsQuery();
