/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { getAddToCartData, getRemoveFromCartData } from '../data/cart';
import { GTM_EVENT_KEY_ADD_TO_CART, GTM_EVENT_KEY_REMOVE_FROM_CART } from '../util/events';
import { pushToDataLayer } from '../util/push';

/** @namespace Scandiweb/Gtm/Event/Cart/fireAddToCartEvent */
export const fireAddToCartEvent = (item, currencyCode) => {
    const execute = async (item, currencyCode) => {
        pushToDataLayer({
            event: GTM_EVENT_KEY_ADD_TO_CART,
            ...await getAddToCartData(item, currencyCode)
        });
    };

    execute(item, currencyCode);
};

/** @namespace Scandiweb/Gtm/Event/Cart/fireRemoveFromCartEvent */
export const fireRemoveFromCartEvent = (item, currencyCode) => {
    const execute = async (item, currencyCode) => {
        pushToDataLayer({
            event: GTM_EVENT_KEY_REMOVE_FROM_CART,
            ...await getRemoveFromCartData(item, currencyCode)
        });
    };

    execute(item, currencyCode);
};
