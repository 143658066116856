/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import UrlRewritesQuery from 'Query/UrlRewrites.query';
import { UrlRewritePageType } from 'Route/UrlRewrites/UrlRewrites.config';
import { fetchQuery } from 'Util/Request/Query';
import getStore from 'Util/Store';

import { waitForCallback } from '../util/wait';

export const DL_VAL_PAGE_HOME = 'homepage';
export const DL_VAL_PAGE_CART = 'cart';
export const DL_VAL_PAGE_CHECKOUT = 'checkout';
export const DL_VAL_PAGE_CHECKOUT_SHIPPING = 'checkout/shipping';
export const DL_VAL_PAGE_CHECKOUT_BILLING = 'checkout/billing';
export const DL_VAL_PAGE_ACCOUNT = 'account';
export const DL_VAL_PAGE_WISHLIST = 'wishlist';
export const DL_VAL_PAGE_FORGOT = 'forgot';
export const DL_VAL_PAGE_CONTACT = 'contact';
export const DL_VAL_PAGE_COMPARE = 'compare';
export const DL_VAL_PAGE_BLOG = 'blog';
export const DL_VAL_PAGE_STORES = 'stores';
export const DL_VAL_PAGE_SEARCH = 'search';
export const DL_VAL_PAGE_CATEGORY = 'category';
export const DL_VAL_PAGE_PRODUCT = 'product';
export const DL_VAL_PAGE_404 = 'not_found';
export const DL_VAL_PAGE_CMS = 'cmspage';

/** @namespace Scandiweb/Gtm/Data/Page/getPathKey */
export const getPathKey = (path) => {
    const pathnameMap = {
        [DL_VAL_PAGE_CART]: '/cart',
        [DL_VAL_PAGE_CHECKOUT_SHIPPING]: '/checkout/shipping',
        [DL_VAL_PAGE_CHECKOUT_BILLING]: '/checkout/billing',
        [DL_VAL_PAGE_CHECKOUT]: '/checkout',
        [DL_VAL_PAGE_ACCOUNT]: '/account',
        [DL_VAL_PAGE_WISHLIST]: '/wishlist',
        [DL_VAL_PAGE_FORGOT]: '/forgot-password',
        [DL_VAL_PAGE_CONTACT]: '/contact',
        [DL_VAL_PAGE_COMPARE]: '/compare',
        [DL_VAL_PAGE_BLOG]: '/blog',
        [DL_VAL_PAGE_STORES]: '/stores',
        [DL_VAL_PAGE_SEARCH]: '/search'
    };

    return Object.entries(pathnameMap).find(([, pathname]) => (
        path.indexOf(pathname) !== -1
    )) || [];
};

/** @namespace Scandiweb/Gtm/Data/Page/getPageData */
export const getPageData = async () => {
    // vvv Wait for store code
    await waitForCallback(() => getStore().getState().ConfigReducer?.store_code);

    const {
        ConfigReducer: {
            store_code
        } = {}
    } = getStore().getState();

    const realPath = window.location.pathname.replace(
        `/${store_code}`,
        ''
    );

    if ((realPath === '/') || (realPath === '')) {
        return { pageType: DL_VAL_PAGE_HOME };
    }

    // vvv Try lookign up the page from an array of pre-defined ones

    const [pathnameKey] = getPathKey(realPath);

    if (pathnameKey) {
        return { pageType: pathnameKey };
    }

    // vvv Wait for store code
    await waitForCallback(() => getStore().getState().UrlRewritesReducer?.urlRewrite?.type);

    const {
        urlRewrite: {
            type,
            notFound = false
        },
        requestedUrl = ''
    } = getStore().getState().UrlRewritesReducer;

    const realRequestedPath = requestedUrl.replace(
        `/${store_code}`,
        ''
    );

    if (notFound) {
        return { pageType: DL_VAL_PAGE_404 };
    }

    if (!type) {
        return { pageType: '' };
    }

    const typeToKeyMap = {
        [UrlRewritePageType?.CMS_PAGE]: `${DL_VAL_PAGE_CMS}${realPath}`,
        [UrlRewritePageType?.CATEGORY]: DL_VAL_PAGE_CATEGORY,
        [UrlRewritePageType?.PRODUCT]: DL_VAL_PAGE_PRODUCT
    };

    if (realPath !== realRequestedPath) {
        const query = UrlRewritesQuery.getQuery({ urlParam: realPath });
        const urlRewrite = await fetchQuery(query);
        const { urlResolver: { type: realType } = {} } = urlRewrite;
        if (realType) {
            return { pageType: typeToKeyMap[realType] };
        }
    }

    return { pageType: typeToKeyMap[type] };
};
