/**
 * Paypal Express compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/PaypalExpress/Query/PaypalBannersConfig/Query */
export class PaypalBannersConfigQuery {
    getQuery() {
        return new Field('paypalBannersConfig')
            .addFieldList(this.getBannersConfigFields());
    }

    getBannersConfigFields() {
        return [
            this.getHomeBannerConfigField(),
            this.getCategoryBannerConfigField(),
            this.getProductBannerConfigField(),
            this.getCartBannerConfigField()
        ];
    }

    getHomeBannerConfigField() {
        return new Field('home')
            .addFieldList(this.getBannerConfigFields());
    }

    getCategoryBannerConfigField() {
        return new Field('category')
            .addFieldList(this.getBannerConfigFields());
    }

    getProductBannerConfigField() {
        return new Field('product')
            .addFieldList(this.getBannerConfigFields());
    }

    getCartBannerConfigField() {
        return new Field('cart')
            .addFieldList(this.getBannerConfigFields());
    }

    getBannerConfigFields() {
        return [
            'isEnabled',
            'position',
            'placement',
            this.getBannerStyleConfigField()
        ];
    }

    getBannerStyleConfigField() {
        return new Field('style')
            .addFieldList(this.getBannerStyleConfigFields());
    }

    getBannerStyleConfigFields() {
        return [
            'layout',
            'color',
            'ratio',
            this.getBannerLogoStyleConfigField(),
            this.getBannerTextStyleConfigField()
        ];
    }

    getBannerLogoStyleConfigField() {
        return new Field('logo')
            .addFieldList(this.getBannerLogoStyleConfigFields());
    }

    getBannerLogoStyleConfigFields() {
        return [
            'type',
            'position'
        ];
    }

    getBannerTextStyleConfigField() {
        return new Field('text')
            .addFieldList(this.getBannerTextStyleConfigFields());
    }

    getBannerTextStyleConfigFields() {
        return [
            'color',
            'size'
        ];
    }
}

export default new PaypalBannersConfigQuery();
